import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import LoadingDiv from "../components/LoadingDiv";
import MainLayout from "../components/layout/MainLayout";

const Home = React.lazy(() => import("../pages/Home"));
const Login = React.lazy(() => import("../pages/Login"));
const SignUp = React.lazy(() => import("../pages/SignUp"));
// const OTP = React.lazy(() => import("../pages/OTP"));
const Profile = React.lazy(() => import("../pages/Profile"));
const Subscription = React.lazy(() => import("../pages/Subscription"));
const GameDetails = React.lazy(() => import("../pages/GameDetails"));
const UpdateProfile = React.lazy(() => import("../pages/UpdateProfile"));
const ShowAll = React.lazy(() => import("../pages/ShowAll"));
const CategoryShowAll = React.lazy(() => import("../pages/CategoryShowAll"));
const Downloadable = React.lazy(() => import("../pages/Downloadable"));
const PlayGame = React.lazy(() => import("../components/home/PlayGame"));
const PackSubscribeCharge = React.lazy(() => import("../pages/PackSubscribeCharge"));
const PlayQuiz = React.lazy(() => import("../pages/PlayQuiz"));
const Quiz = React.lazy(() => import("../pages/Quiz"));
const PackSubscriptionList = React.lazy(() => import("../pages/PackSubscriptionList"));
const Help = React.lazy(() => import("../pages/Help"));
const UserQuizScore = React.lazy(() => import("../pages/UserQuizScore"));

const Routers = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <div>
      <Router>
        <ScrollToTop />
        <MainLayout>
          <Suspense fallback={<LoadingDiv />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/help" element={<Help />} />
              <Route path="/downloadable" element={<Downloadable />} />
              <Route path="/on-demand-play/:gameUrl" element={<PlayGame />} />
              <Route path="/pack-subscribe" element={<PackSubscriptionList />} />
              <Route path="/subscribe" element={<Subscription />} />
              <Route path="/play/:gameUrl" element={<PlayGame />} />

              <Route
                path="/:pathId/see-all/:gameType/:patchName"
                element={<ShowAll />}
              />

              <Route
                path="/category/:categoryName/:id"
                element={<CategoryShowAll />}
              />
              <Route path="/game/:id/:type" element={<GameDetails />} />
              <Route path="/pack-subscribe-charge" element={<PackSubscribeCharge />} />
              <Route path="/quiz" element={<Quiz />} />
              <Route path="/play-quiz" element={<PlayQuiz />} />
              <Route path="/user-quiz-score" element={<UserQuizScore />} />

              <Route element={<PublicRoute />}>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                {/* <Route path="/otp" element={<OTP />} /> */}
              </Route>

              <Route element={<PrivateRoute />}>
                <Route path="/profile" element={<Profile />} />
                <Route path="/update-profile" element={<UpdateProfile />} />
              </Route>
            </Routes>
          </Suspense>
        </MainLayout>
      </Router>
    </div>
  );
};

export default Routers;
