import React from "react";
import axios from "axios";
import Routers from "./routers/Routers";


function App() {
  axios.defaults.baseURL = "http://devapi.play2win.zone/api"; // Dev
  // axios.defaults.baseURL = "http://api.myplay.games/api"; // Live
  // axios.defaults.baseURL = "http://localhost:5274/api";

  return (
    <div>
      <Routers />
    </div>
  );
}

export default App;
